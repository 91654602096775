import React,{useState} from 'react'
import {Box,IconButton,Table,TableBody,TableCell,LinearProgress,
    //Paper,Collapse,Button,Card,
    TableContainer,TableHead,TableRow,Tooltip,TablePagination} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CreateIcon from '@material-ui/icons/Create';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord'; 
import PaginacionTab from '../layout/PaginacionTab'
import moment from 'moment';
import EditarUsuario from './EditarUsuario'
import EditarStatus from './EditarStatusUsu'
 
const useStyles = makeStyles({
    root: {
      //width: '100%',
    },
    container: {
      //maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
      maxWidth:'100%',
      minWidth:44+'rem',
    },
    container2: {
      maxHeight: 550,
      //maxHeight: window.innerHeight-170,
      //minHeight: 280,
    },
    tableRow: {
      "&$selected, &$selected:hover": {
        backgroundColor: process.env.REACT_APP_Color_Second
      }
    },
    tableCell: {
      "$selected &": {
        color: "white"
      }
    },
    hover: {},
    selected: {}
  });
  const columnas=[          
    {
      id:1,
      titulo:"Usuario",                                                
      alinear:'center'
    },   
    {
      id:3,
      titulo:"Responsable",                                                
      alinear:'center'
    },    
      
    {
      id:2,
      titulo:"Email",                                                
      alinear:'center'
    },   
        
   /*  {
      id:4,
      titulo:"Alta",                                                
      alinear:'center'
    },  */       
  ];

const TablaUsuarios = ({listUsu,auxActualizar,setActualizar,listTipoUsu }) => {
    const classes = useStyles();
    let colorLetra=process.env.REACT_APP_Fondo_Color
    const [datos, setDatos] = React.useState([])  
    const[sinDatos,guardarSinDatos]= React.useState(true)
    const [espera,setEspera]=React.useState(false);
    const [selectedID, setSelectedID] = React.useState(null); 
    const [usuSelecc,setUsuSelecc]= useState([]); 
    const [modalEditCliAbierto,setModalEditCliAbierto]= useState(false); 
    const [modalEditStdUsu,setModalEditStdUsu]= useState(false);        
    const [totalPaginas,setTotalPaginas]=useState(0);
    const [numPagina,setNumPagina]=useState(0);
    const [alturaTabla,setAlturaTabla] = useState(150)
    let limiteXPag=150
  React.useEffect(()=>
  {
    //console.log(listUsu);
    if(listUsu.length>limiteXPag)
    { let auxTam = listUsu.length;
      if(auxTam%limiteXPag===0)
      { let pagina=Math.trunc(auxTam/limiteXPag)
        setTotalPaginas(pagina)   
      }
      else
      { let pagina=Math.trunc(auxTam/limiteXPag)
        pagina=pagina+1;
        setTotalPaginas(pagina)
      }
      setNumPagina(0) 
      setDatos(listUsu.slice(0,limiteXPag))
    }
    else
    { setTotalPaginas(1)
      setNumPagina(0)
      setDatos(listUsu)
    }

    let auxAltura = window.innerHeight<937 ? 
      (listUsu.length>limiteXPag ? window.innerHeight-220 : window.innerHeight-170) 
    : (listUsu.length>limiteXPag ? window.innerHeight-220 : window.innerHeight-220)

    setAlturaTabla(auxAltura)
    
               
  },[listUsu])

  const seleccionado=(auxId,auxOrden)=>{
    setSelectedID(auxId)   
  }
   
  function abrirModalEditCliente(auxClient)
  {  setUsuSelecc(auxClient)
    //console.log("abriendo modal");  
    setModalEditCliAbierto(true);    
  }

  function abrirModalEditStdUsu(auxClient)
  { setUsuSelecc(auxClient)
    //console.log("abriendo modal");  
    setModalEditStdUsu(true);    
  }
   
  function onChangePagina(event, newPage){
    event.preventDefault();
    if (newPage ===0) 
    {
      setDatos(listUsu.slice(0,limiteXPag))   
    }
    else
    {
      setDatos(listUsu.slice(newPage * limiteXPag, newPage * limiteXPag + limiteXPag))   
    }
    setNumPagina(newPage)
  }

  function generarFooter(){
    return(
    <TablePagination
      rowsPerPageOptions={false} colSpan={3} 
      rowsPerPage={limiteXPag} count={listUsu.length}
      page={numPagina} lang='es' onPageChange={onChangePagina}
      labelDisplayedRows={
        ({ from, to, count }) => { return '' + from + '-' + to + ' de ' + count }
      }
      ActionsComponent={PaginacionTab}
    />)
  }

  function Renglon(props)
  {
    const { row } = props;  
    let auxColorStd=row.Status===1 ? "#0158A5":"#d40100";    
    return (
    <React.Fragment>
      <TableRow onClick={ () => { seleccionado(row.Id,row); } }
        className={classes.tableRow} selected={ selectedID === row.Id}
        //onContextMenu={(event)=>guadarPosCursor(event,row)} 
        classes={{ hover: classes.hover, selected: classes.selected }}        
      > 
        {/*  
        */}     
        <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          <Tooltip title={`Editar Estatus`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditStdUsu(row)} component="span">
              {selectedID === row.Id ? 
                <FiberManualRecordIcon style={{color:'white',width:18+'px'}} />
                :<FiberManualRecordIcon style={{width:18+'px',color: auxColorStd }} color="primary"/>
              }
            </IconButton>                            
          </Tooltip>                                                                                                                                              
        </TableCell>
        <TableCell align="center" padding={'none'} style={{ width:10+'px'}} >
          <Tooltip title={`Editar Usuario`}>
            <IconButton   aria-label="add circle" size="small" onClick={() => abrirModalEditCliente(row)} component="span">
              {selectedID === row.Id ? 
                <CreateIcon style={{color:'white',width:18+'px'}} />
                :<CreateIcon style={{width:18+'px'}} color="primary"/>
              }
            </IconButton>                            
          </Tooltip>                                                                                                                                              
        </TableCell> 
        
      {/*  <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ fontWeight:700,width:40+'px', paddingRight:2+'px'}} >
          {row.Id}
        </TableCell>  */} 
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:220+'px',fontSize:12+'px'}} >
          {row.Responsable}
        </TableCell> 
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:250+'px',fontSize:12+'px'}} >
          {row.Tipo===5 && row?.MunNom !== undefined ? row.MunNom+" "+row.Nombre : row.Nombre}
        </TableCell>          
        <TableCell  className={classes.tableCell} align="left" padding={'none'} style={{ width:120+'px',fontSize:12+'px',paddingLeft:8+'px'}} >
          {row.Email}              
        </TableCell>
        {/*         
        <TableCell  className={classes.tableCell} align="center" padding={'none'} style={{ width:80+'px',fontSize:12+'px',paddingLeft:8+'px'}} >          
          {moment.utc( row.FAlta).format('YYYY-MM-DD HH:mm') === "1900-01-01 00:00"
            ? "" : moment.utc( row.FAlta).format('DD/MM/YYYY')}       
        </TableCell>      */}         
                         
      </TableRow>        
    </React.Fragment>
  );}

  function tablaCompleta(auxlista){  
   
    return(
    <TableContainer className={classes.container} style={{height:alturaTabla}} >
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>     
          <TableCell style={{ color:colorLetra}} 
            key={1}    align={'center'} padding={'none'} 
          >              
          </TableCell>
          <TableCell style={{ color:colorLetra}} 
            key={1}    align={'center'} padding={'none'} 
          >  
            {auxlista.length}            
          </TableCell>                                  
          {/* <TableCell style={{ color:colorLetra}} 
            key={1}    align={'center'} padding={'none'} 
          >
            {}
          </TableCell>        */}            
          {columnas.map(row => {              
            return(  
              <TableCell style={{ color:colorLetra}} 
                key={row.id}    align={row.alinear} padding={'none'} 
              >
                {row.titulo}
              </TableCell>    
            )                
          })}           
        </TableRow>
      </TableHead>
      <TableBody>
        {auxlista.length!=0 ? (auxlista.map((row) => (
            <Renglon key={row.Id} row={row} />
        ))):null}
      </TableBody>
    </Table>
    </TableContainer>
    )
  }
  
  const tablaSinDatos=()=>{
  return(
  <TableContainer className={classes.container2}>
    <Table stickyHeader aria-label="sticky table">
      <TableHead>
        <TableRow>            
          {columnas.map(row => {              
            return(  
            <TableCell style={{ backgroundColor:colorLetra,color:'white'}} 
              key={row.id}    align={row.alinear} padding={'none'} 
            >
              {row.titulo}
            </TableCell>    
            )                
          })}                                                
        </TableRow>
    </TableHead>
    <TableBody>
      <TableRow 
        className={classes.tableRow}
      >                  
        <TableCell  className={classes.tableCell} align="center">Sin </TableCell>          
        <TableCell  className={classes.tableCell} align="center">Usuarios</TableCell>                       
        <TableCell  className={classes.tableCell} align="center">Registrado</TableCell>                               
      </TableRow>
    </TableBody>
  </Table>
  </TableContainer>
  )}

  const paginacion = totalPaginas>1 ? generarFooter() : null;
  const contenido = datos.length!=0 ?tablaCompleta(datos):tablaSinDatos()
  const tabla = espera ?  (<Box pt={3}><LinearProgress/> </Box>) :( contenido )  
                                        
  const modEditCli = modalEditCliAbierto ? 
                    ( <EditarUsuario modalAbierto={modalEditCliAbierto}  
                        setModalAbierto={setModalEditCliAbierto} usrSelecc={usuSelecc}
                        titulo={'Editar '+ usuSelecc.Responsable }                          
                        setActualizar={setActualizar} actualizar={auxActualizar} listTipoUsu ={listTipoUsu }
                        subtitulo={ usuSelecc.Tipo === 5 ? usuSelecc.MunNom :"" }
                      />
                    ): null;     
  const modEditStdUsu = modalEditStdUsu ? 
                      ( <EditarStatus modalAbierto={modalEditStdUsu}  
                          setModalAbierto={setModalEditStdUsu} usrSelecc={usuSelecc}
                          titulo={'Editar Usuario'  }                          
                          setActualizar={setActualizar} actualizar={auxActualizar} 
                        />
                      ): null;     
                                                                                                                                            
  return (
  <div style={{minWidth:36+'rem',marginTop:.5+'rem',maxWidth:42+'rem'}}>        
    {tabla}
    {paginacion}
    {modEditStdUsu}
    {modEditCli}
  </div>
  )
}

export default TablaUsuarios
import React,{useState} from "react";
import { List, ListItem, Collapse,Typography,
   ListItemText, Divider, } from "@material-ui/core";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { useHistory } from "react-router-dom";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

const Listas = ({funcionAbrir}) => {
  let history = useHistory();
  const classes = useStyles();
  const [open, setOpen] = useState(true);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const [open4, setOpen4] = useState(false);
  const [mostrarUsu, setMostrarUsu] = useState(false);
  const [verTipoUsu, setVerTipoUsu] = useState(false);
  const[usrID,setUsrId]=  useState(0);
  const[usrTipo,setUsrTipo]= useState(0);
  const nvl = localStorage.getItem("Tipo");
  React.useEffect(() => {
    const auxOpera = localStorage.getItem("Operacion");
    
    const usuID = localStorage.getItem('UsuId');
    let aux1=usuID!== null && parseInt(usuID) === 1 ? parseInt(usuID):0
    setUsrId(aux1)
    let aux2=nvl!== null && parseInt(nvl) !== 0 ? parseInt(nvl):0
    setUsrTipo(aux2)
    if (nvl !== null && parseInt(nvl) !== 2) {
      setVerTipoUsu(true);
    } else {
      setVerTipoUsu(false);
    }

    let auxValida = false;
    if (auxOpera.indexOf("0") !== -1) {
      auxValida = true;
    }
    if (!auxValida && auxOpera.indexOf("1") !== -1) {
      auxValida = true;
    }
    if (!auxValida && auxOpera.indexOf("2") !== -1) {
      auxValida = true;
    }
    if (!auxValida && auxOpera.indexOf("3") !== -1) {
      auxValida = true;
    }
    /* if (auxValida) 
      { setMostrarUsu(true)
      } 
      else 
      { setMostrarUsu(false)
      } */
    if (parseInt(nvl) !== 2) {
      setMostrarUsu(true);
    } else {
      setMostrarUsu(false);
    }
  }, []);

  const cambiar = (opcion) => {
    if (opcion === 1) 
    { setOpen(!open);
    } 
    else{
      if (opcion === 2) 
      { setOpen2(!open2);
      } 
      else{
        if (opcion === 3) 
        { setOpen3(!open3);
        } 
        else{
          if (opcion === 4) 
          { setOpen4(!open4);
          }
        }
      }
    }
  };

  const nuevaVentana = (elLink) => {
    funcionAbrir()
    history.push(elLink);
  };

  /* const contacto=(e)=> {
    e.preventDefault()        
  } */

  const ItemLista = (link, descripcion) => {
    return (
    <ListItem button className={classes.nested}
      onClick={() => nuevaVentana(link)}
    >
      <ListItemText
        secondary={
          <Typography variant="body" style={{ color: "white" }}>
            {descripcion}
          </Typography>
        }
      />
    </ListItem>
    );
  };

  return (
    <div>
      <List component="nav">
        <ListItem button onClick={() => cambiar(1)}>
          <ListItemText primary="Menu" />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {verTipoUsu && usrTipo!==7  ? ItemLista("/inicio", "Inicio") : null}
            { usrTipo!==7 ?ItemLista("/lista-perfil", "Perfiles"):null}
            { usrTipo===7 ?ItemLista("/perfil-externo", "Perfiles"):null}
            {/* usrID===1 ?ItemLista("/sugerencias", "Sugerencias") :null  */}            
            {usrID===1 || ( nvl !== null && (parseInt(nvl) > 2 || parseInt(nvl) === 1 )) && usrTipo!==7? ItemLista("/lista-usuario", "Usuarios") : null}
            { usrID===1 || ( nvl !== null && parseInt(nvl) === 1 )  ?ItemLista("/apoyo", "Apoyos") :null  }
            { usrID===1 || ( nvl !== null && parseInt(nvl) === 1 ) ?ItemLista("/recorrido", "Recorridos") :null  }
            {/* usrID===1|| ( nvl !== null && parseInt(nvl) === 1 ) ? ItemLista("/registro-previo", "Registro Prospecto") : null */}            
            {usrID===1 ? ItemLista("/mapa-secc", "Mapa Secciones") : null }
          </List>
        </Collapse>
        <Divider />

        {/*<ListItem button onClick={contacto} >                    
            <ListItemText  
              secondary={<Typography variant="p" style={{ color: 'white' }}>
              Contacto</Typography>}
            />                    
        </ListItem> */}
      </List>
    </div>
  );
};

export default Listas;

import React,{useEffect,useState} from 'react'
import {AuthContext} from '../context/AuthContext'
import {GeneralesContext} from '../context/GeneralesContext'
import { authUser } from '../funciones/AuthUser'
import { TextField,IconButton,Box,Tooltip,LinearProgress,FormControl,
    //FormLabel,FormControlLabel,RadioGroup,Radio
    Select,MenuItem,InputLabel, Typography,} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import RefreshIcon from '@material-ui/icons/Refresh';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import TablaUsuario from './TablaUsuarios'
import NvoUsuario from './NuevoUsuario'
import { useHistory } from "react-router-dom";
import axios from 'axios';
import qs from 'qs';
import {llamaApiCargaToken,ErrorGeneral } from '../funciones/ClienteAxios';
const useStyles = makeStyles({    
    buscador:{
    width:'30em'
    }, 
});

const UsuarioInicio = () => {
    let history = useHistory();
    const {usuario,guardarUsuario}=React.useContext(AuthContext);
    const {generales,guardarGenerales}=React.useContext(GeneralesContext); 
   // let history = useHistory();  
    const[usrBusq,setUsrBusq]=React.useState({nombre:'',nombreFiltrado:''});
    const[listUsu,setListUsu]=React.useState([]);
    const[espera,setEspera]=React.useState(false);
    const{nombre,nombreFiltrado}=usrBusq
    const classes = useStyles();
    const[actualizar,setActualizar]=useState(false);
    const [modalNvoUsrAbierto,setModalNvoUsrAbierto]= useState(false); 
    const[ TipoUsu, setTipoUsu]= React.useState(1); 
    const[ mensaje, setTMensaje]= React.useState(false); 
    //const [modalNvoCliAbierto,setModalNvoCliAbierto]= useState(false);
    const [listTipoUsu, setListTipoUsu] = React.useState([]); 
    const[auxValida,setAuxValida]=React.useState([])
    const[listUsuCoord,setListUsuCoord]=React.useState([])
    const[listUsuCoordFtr,setListUsuCoordFtr]=React.useState([])
    const source = axios.CancelToken.source();  
    let colorCheckR='#0EA7E0'
    useEffect(() => {
        const usuID = localStorage.getItem('UsuId');
        const nvl= localStorage.getItem('Tipo');            
        const usu= localStorage.getItem('UsuarioNom');
        
        const llenaListTipo = () => {               
            let data = qs.stringify({
                usuario:usuID  ,   
                idDispositivo:'iu87ygubhjjij',
            });   
            let url = "/usuario-tipo-list";
            function respuesta(aux) {
                if (aux.length !== 0) 
                {   let auxFiltrado = aux.filter((tipo)=>tipo.Id >= nvl || tipo.Id===2)                     
                    setTipoUsu(parseInt(nvl) )
                    setListTipoUsu(auxFiltrado)   
                }       
            }    
            llamaApiCargaToken(data, url,respuesta,ErrorGeneral,setEspera,history,source);
        }         
        
        const veriSesion =  () => {            
            let auxValidacion=authUser()
            const auxOpera= localStorage.getItem('Operacion');
            let auxValidaOp=false
            if ( auxOpera.indexOf('0') !== -1) 
            {   auxValidaOp=true
            }
            if (!auxValidaOp && auxOpera.indexOf('1')!== -1) 
            {   auxValidaOp=true
            }
            if (!auxValidaOp && auxOpera.indexOf('2')!== -1) 
            {   auxValidaOp=true
            }
            if (!auxValidaOp && auxOpera.indexOf('3')!== -1) 
            {   auxValidaOp=true
            }            
            //console.log(!auxValidacion);
            if(!auxValidacion )
            {   console.log("cerrando sesion 1");
                guardarUsuario([])				
                guardarGenerales([])    
                localStorage.clear();            	             
                history.push("/")	                	
            }
            else
            {   
                if (usuario.length==0) 
                {
                    guardarUsuario({Nombre:usu,nivel:nvl});                                                             
                }                                                      
                guardarGenerales({...generales,Titulo:'Listado Usuarios'})   

                
                if (usuID !== null && usuID !== undefined 
                    && (parseInt(usuID)===1 || parseInt(nvl) > 2 || parseInt(nvl) === 1)) 
                {
                    if ( parseInt(nvl) > 2 ) 
                    {   llenaListUsuCord(usuID)
                        llenaListTipo()  
                        setAuxValida(false)  
                    } 
                    else 
                    {   llenaListTipo()    
                        setAuxValida(auxValidaOp)                                            
                    }  
                }
                else
                {      console.log("cerrando sesion 2");
                    localStorage.clear();                            
                    history.push("/");  
                }              
            }            
        }        
        veriSesion();	

        return ()=> {
            source.cancel();
        }  
    }, [])

    async function llenaListUsuCord(auxUsuId)  {           
        setEspera(true)       
        let auxU=process.env.REACT_APP_LINK +`/usuario-list-coordinador`;       
        let token =localStorage.getItem('token20') ;                
        let dataInfo = qs.stringify({     
            'usuario':auxUsuId  ,            
            'idDispositivo':'8978yuhjnfrsdsd'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {         
            aux=  response.data;                    
            if (aux.length !== 0) 
            {   setListUsuCoord(aux)
                setListUsuCoordFtr(aux)
            }                                         
        })
        .catch(function (error) {          
        console.log(`Error: ${error}`);              
        if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
        {
            console.log('datos incorrectos' );  
        }else
        {
            if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
            {
            console.log('loguearse de nuevo');
            localStorage.clear();                      
            history.push("/");                              
            } 
        }
        });                
        setEspera(false)     
    };

    useEffect(()=>{
        const usuID = localStorage.getItem('UsuId');
        const nvl= localStorage.getItem('Tipo');    
        if (nombre.length>=1 ) 
        {   // console.log("saltando validacion");
            llamadaBusqUsu(nombre)    
        }        
        else
        {   if (parseInt(nvl) > 2) 
            {
                llenaListUsuCord(usuID)
            }            
        }
        
    },[actualizar])
    
   
    const guardarTipoSelect=(e)=>{    
        if (e!== null && e!== undefined) 
        { //setMuniSelect(e)       
            setTipoUsu( e.target.value)      
        }else
        { setTipoUsu( "" )           
        }        
    }

    async function llamadaBusqUsu(auxNombre)  {       
        setEspera(true) ;
        const source = axios.CancelToken.source(); 
        const usuID = localStorage.getItem('UsuId');
        let auxU= process.env.REACT_APP_LINK +`/usuario-list`;               
        let token =localStorage.getItem('token20') ;               
        let dataInfo = qs.stringify({                                 
            'usuario':usuID,
            'cadena':auxNombre,
            'tipo': TipoUsu,
            'idDispositivo':'ifrekvjkxcdwdcwe'
            });
        let config = {
            url:auxU ,
            method: 'POST',
            headers: { 'access-token' : token, 'Content-Type': 'application/x-www-form-urlencoded' },            
            data: dataInfo,
            cancelToken: source.token
        };
        let aux ={};           
        await axios(config)
        .then(function (response) {                
            aux=  response.data;      
            //console.log(aux);                     
            if (aux[0].Id != -1) 
            { // console.log(aux);  
              //guardarSinDatos(true)
              setTMensaje(false)         
              aux.sort(function (a, b) { return a.Id - b.Id; });                                                    
              setListUsu(aux)
            }
            else
            {   setListUsu([])
                setTMensaje(true)
              //guardarSinDatos(false)       
            }                                            
        })
        .catch(function (error) {                
          console.log(error);        
          if( error && error.response && error.response.data && error.response.data.mensaje==="Datos Incorrectos")
          {
            console.log('datos incorrectos' );  
          }else
          {
            if ( ( error && error.response && error.response.data && error.response.data.error.name==="JsonWebTokenError" )||
                ( error && error.response && error.response.data && error.response.data.error.name==="TokenExpiredError" ) ) 
            {
              console.log('loguearse de nuevo');
              localStorage.clear();                      
              history.push("/");                              
            } 
          }
        });              
        setEspera(false)  ;
    };
    
    const btnCargar =()=>{
        if (nombre.length>=1 ) {
            llamadaBusqUsu(nombre)
        } 
        else 
        {
            
        }
    }

    const btnCargar2 =()=>{
        const usuID = localStorage.getItem('UsuId');
        const nvl= localStorage.getItem('Tipo');    
        if (parseInt(nvl) > 2) 
        {
            llenaListUsuCord(usuID)
        } 
    }  

    const llamadaxEnter=(event)=>{
        if (event.keyCode === 13 && nombre.length>=1) 
        {   llamadaBusqUsu(nombre)            
        }
    }

    const onChange = e =>
    {         
        let expNopermitida = new RegExp('[:$!%({})/*|]');
        //let expMenos = new RegExp('-');
        let expMas = new RegExp('[+]');     
        let expCadena = new RegExp('[A-Za-z.0-9_-]');
        if ((expCadena.test(e.target.value) && !expNopermitida.test(e.target.value) //&& !expMenos.test(e.target.value) 
            &&!expMas.test(e.target.value)) ||e.target.value===' '||e.target.value==='') 
        {
            setUsrBusq({
                ...usrBusq, //copiamos el nombre
                [e.target.name] : e.target.value  
            })
        }         
    };

    function abrirModalNvoUsr(){  
        console.log("abriendo modal");  
        setModalNvoUsrAbierto(true);    
    }

    let colorLetra=process.env.REACT_APP_Fondo_Color
    const checkTipo=  listTipoUsu.length > 0 ?       
        <FormControl  style={{marginLeft:1+'rem', minWidth:14+'rem'}} >
            <InputLabel id="tipo">Tipo</InputLabel>
            <Select  
            label="Tipo" id="TipoUsu" name="TipoUsu"
            value={TipoUsu} onChange={guardarTipoSelect}
            >
            {listTipoUsu.map((secc, index) => {
                if(secc.Id>0) {
                return(
                <MenuItem value={secc.Id} key={secc.Id}>
                    <em>{secc.Nom }</em>
                </MenuItem>
                )}
            } )}     
            </Select>
        </FormControl>        
        :null   
    
    const menuAdmin=()=>{
    return(
    <Box display={"flex"} flexDirection="row">
        <Box mt={2}>        
            <TextField className={classes.buscador} placeholder="Usuario" 
                name="nombre" onKeyUp={(event)=>  llamadaxEnter(event)}
                value={nombre} onChange={onChange} 
            />
            <Tooltip title={`Actualizar lista usuarios`}>
                <IconButton aria-label="actualizar" onClick={() => btnCargar()} 
                    component="span" size="small" 
                >
                    <RefreshIcon style={{color:colorLetra  }} />
                </IconButton>
            </Tooltip>
            {/*  */}
            <Tooltip title={`Crear Usuario`}>
                <IconButton aria-label="agregar nuevo" onClick={() => abrirModalNvoUsr()} 
                    component="span" size="small" 
                >
                    <AddCircleOutlineIcon style={{color:colorLetra }} />  
                </IconButton>
            </Tooltip>
        </Box>
        {checkTipo}
    </Box>)
    }

    const menuUsu=()=>{
        return(
        <Box display={"flex"} flexDirection="row">
            <Box mt={2}>        
                <TextField className={classes.buscador} placeholder="Usuario" 
                    name="nombreFiltrado" onKeyUp={(event)=>  llamadaxEnter(event)}
                    value={nombreFiltrado} onChange={onChange}
                />                          
            </Box>
            <Tooltip title={`Actualizar lista usuarios`} >
                <IconButton aria-label="actualizar" onClick={() => btnCargar2()} 
                    component="span" size="small" style={{marginTop:.8+'rem'}}
                >
                    <RefreshIcon style={{color:colorLetra  }} />
                </IconButton>
            </Tooltip>           
        </Box>)
    }    
    
    const menuUsuLista=()=>{
        return(
        <Box display={"flex"} flexDirection="row">
            <Box mt={2}> 
             <TextField className={classes.buscador} placeholder="Usuario" 
                name="nombre" onKeyUp={(event)=>  llamadaxEnter(event)}
                value={nombre} onChange={onChange} 
            />
            <Tooltip title={`Actualizar lista usuarios`}>
                <IconButton aria-label="actualizar" onClick={() => btnCargar()} 
                    component="span" size="small" 
                >
                    <RefreshIcon style={{color:colorLetra  }} />
                </IconButton>
            </Tooltip>
            </Box>
            {checkTipo}        
        </Box>)
    } 

    const tablaSinFiltro=(auxLista)=>{
        return(
        <TablaUsuario auxNombre={nombre} listUsu={auxLista} 
            auxActualizar={actualizar} setActualizar={setActualizar}
            listTipoUsu={listTipoUsu}  
        />
        )
    }
    
    function filtro(){        
        let auxCadena=nombreFiltrado.trim();         
        let info=listUsuCoord.filter((dat)=>dat.Nombre.toUpperCase().search(auxCadena.toUpperCase() ) !== -1)         
        return tablaSinFiltro(info);
    }

    const tablaCoordUsu= nombreFiltrado.length >0 ? filtro() : tablaSinFiltro(listUsuCoord)
    const modNvoUsr = modalNvoUsrAbierto ? 
                        ( <NvoUsuario modalAbierto={modalNvoUsrAbierto}  
                            setModalAbierto={setModalNvoUsrAbierto} titulo={'Nuevo Usuario'}
                            //setActualizar={setActualizar} actualizar={actualizar}
                            listTipoUsu={listTipoUsu} setListTipoUsu={setListTipoUsu}
                        />
                        ): null; 
    const barraCarga = espera ? <Box pt={3}> <LinearProgress/>  </Box>  :null   
    
    //valida que solo el usuario de tipo admin cree usuarios
    const menu = auxValida ? menuAdmin() : menuUsuLista()

    return (
    <Box mt={1}>
        {menu}
        <br/> 
        {mensaje?<Typography>Sin resultados</Typography>:null}
        {/* listUsuCoord.length>0 && !espera ? 
            tablaCoordUsu
        :null */ }        
        {/* auxValida && listUsu.length>0 && !espera ? 
            <TablaUsuario auxNombre={nombre} listUsu={listUsu} 
                auxActualizar={actualizar} setActualizar={setActualizar}
                listTipoUsu={listTipoUsu}  
            />
        :null */ }
        { listUsu.length>0 && !espera ? 
            <TablaUsuario auxNombre={nombre} listUsu={listUsu} 
                auxActualizar={actualizar} setActualizar={setActualizar}
                listTipoUsu={listTipoUsu}  
            />
        :null }
        {barraCarga }
        {modNvoUsr}
    </Box>
    )
}

export default UsuarioInicio

import React, { useEffect, useState } from "react";
import Mapa from "./Mapa";
import {  Box, Paper, Card ,IconButton,Tooltip} from "@material-ui/core";
import { generaCoordenadaDinam } from "../funciones/Mapas/FormatoCoord";
import { obtenColor } from "./ArrayColor";
import InfoIcon from '@material-ui/icons/Info';
import ModalListAvanze from "./ModalListAvanze";

const arrEjem=[{lng:4,lat:7},{lng:1.5,lat:6},{lng:2,lat:1},{lng:2,lat:4},{lng:4,lat:3},{lng:1,lat:3},{lng:3,lat:2},{lng:2,lat:4}]
const arrEjem2=[[4,7],[1.5,6],[2,1],[2,4],[4,3],[1,3],[3,2],[2,4]]
const MapaPerfil = ({ muniGps, zoom,nomMuni ,tipo,coordPerfiles,auxMapVar}) => {
  const libreriaG = [process.env.REACT_APP_GOOGLE_LIBRARIE];
  let altuTamMax = 600;
  const [listSeccion, setListSeccion] = useState([]);
  const [listCentro, setListCentro] = useState([]);
  const [modalAbierto,setModalAbierto] = useState(false)

  useEffect(() => {
    let auxSecc = [];
    let centro=[]
     //console.log(muniGps);
    muniGps.forEach((seccion,index) => {
      let aux = [];
      //console.log(seccion.Avance / seccion.Meta);

      seccion.gps.forEach((element) => {
        let pathSecc = generaCoordenadaDinam(element.lat, element.lon);
        aux.push(pathSecc);
      });
      auxSecc.push({
        coordenada: aux,
        color: obtenColor((seccion.Avance / seccion.Meta) * 100),
        nombre:seccion.Nom,
        Id:seccion.Id,
        avance:Math.round((seccion.Avance / seccion.Meta) * 100),
        avanceNum:seccion.Avance,
        meta:seccion.Meta
      });

      
      let snOrd=ordenarCoord(auxSecc[index].coordenada)
      centro.push({coordenada:generaCoordenadaDinam(snOrd.coordX, snOrd.coordY),nombre:"Sección "+seccion.Nom})
      
      
    });
    //console.log(coordPerfiles);
    setListCentro(centro)
    setListSeccion(auxSecc);
    //console.log(auxSecc);
    
    //console.log(ordenarCoord(seccion.gps));

  }, [muniGps]);

  const ordCorto=(arreglo)=>{
    let respuesta=arreglo
    respuesta.sort( (el1, el2) => {
      if(el1> el2 ) // again- I used > sign, put the actual comparison logic here, i.g: el1.coordinateid[0] > el2.coordinateid[0] and so on
        return -1;
      else return 1;
    });
    return respuesta
  }
  const ordenarCoord=(coord)=>{
    let aux1=[] , aux2=[] 
    coord.forEach((elemento,index)=>{
      aux1[index]=elemento.lat
      aux2[index]=elemento.lng      
    })    
    let ord1=ordCorto(aux1)
    let ord2=ordCorto(aux2)
    let coordX= ord1[ord1.length-1] + (ord1[0]-ord1[ord1.length-1])/2
    let coordY= ord2[ord2.length-1] + (ord2[0]-ord2[ord1.length-1])/2
    //console.log(coordX,coordY);
    return {coordX,coordY}
  }


  const abrirModal=()=>{
    return <Box style={{ position: "absolute", width: 2 + "rem" }}>
    <Paper elevation={3}>
      <Card>
      <Tooltip title={"Mostrar más"}> 
        <IconButton   aria-label="agregar imagen" size="small" onClick={(e) => setModalAbierto(true)} 
          component="span">
          <InfoIcon  color="primary" /> 
        </IconButton>
      </Tooltip>
      </Card>
    </Paper>
  </Box>
  }
  const tamanioH =
    window.innerHeight < altuTamMax
      ? window.innerHeight - 150
      : window.innerHeight - 250;

  const modAvanze = modalAbierto ? <ModalListAvanze modalAbierto={modalAbierto} 
      setModalAbierto={setModalAbierto} listSecc={listSeccion} nomMuni={nomMuni}
      tipo={tipo} listCentro={listCentro} coordPerfiles={coordPerfiles}
    /> :null
  return (
    <div>
      <Box width={35 + "rem"} ml={2}>
        {modAvanze}
        {listSeccion.length !== 0 && !modalAbierto? (
          <Mapa
            zoom={zoom} auxMapVar={auxMapVar}
            tamanio={{ height: tamanioH, width: "100%" }}
            libreriaG={libreriaG} abrirModal={abrirModal}
            listSeccion={listSeccion}  coordPerfiles={coordPerfiles}     
            listCentro={listCentro}    tipo={tipo}   
          />
        ) : null}
      </Box>
    </div>
  );
};

export default MapaPerfil;

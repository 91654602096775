import {ErrorGeneral, llamaApiCargaToken} from "../funciones/ClienteAxios";
import {authUser} from "../funciones/AuthUser";
import {useHistory} from "react-router-dom";
import {makeStyles} from '@material-ui/core/styles';
import qs from "qs";
import axios from "axios";
import React, {useEffect, useState} from "react";
import {GeneralesContext} from '../context/GeneralesContext'
import {
    Box,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    LinearProgress,
    MenuItem,
    Paper,
    Select,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Tooltip
} from "@material-ui/core";
import EditarApoyo from "./EditarApoyo";
import SearchIcon from "@material-ui/icons/Search";
import ReplayIcon from "@material-ui/icons/Replay";
import ApoyosSelected from "./ApoyosSelected";
import EditIcon from "@material-ui/icons/Edit";
import PaginacionTab from "../layout/PaginacionTab";
import {numeroPag} from "../funciones/Paginacion";

const useStyles = makeStyles((theme) => ({
    alturaTabla: {
        maxHeight: "65vh", minHeight: "50vh", width: 60 + 'rem', overflowY: 'auto'
    },

    tableRow: {
        "&$selected, &$selected:hover": {
            backgroundColor: process.env.REACT_APP_Color_Second
        }
    }, tableCell: {
        "$selected &": {
            color: "white"
        }
    }, hover: {}, selected: {}
}));

const Apoyo = () => {

    const classes = useStyles();
    let history = useHistory();
    const source = axios.CancelToken.source();
    let UsuId = localStorage.getItem('UsuId');
    let Dispvo = localStorage.getItem("Dispvo");
    let auxDispV = Dispvo.length >= 12 ? Dispvo : "451224452121";
    const {generales, guardarGenerales} = React.useContext(GeneralesContext);
    const [espera, setEspera] = useState(false);
    //const [cargando, setCargando] = useState(null);
    const [cargandoPerfil, setCargandoPerfil] = useState(null);

    const [totalPaginas, setTotalPaginas] = useState(0);
    const [numPagina, setNumPagina] = useState(0);
    let tamMax = 250
    const [datos, guardarDatos] = useState([])

    const [perfil, setPerfil] = useState([]);
    //const [perfilNuevo, setPerfilNuevo] = useState([]);
    //const [perfilBuscado, setPerfilBuscado] = useState([]);
    const [idBotonEditar, setIdBotonEditar] = useState(-1);
    const [modalEditarAbierto, setModalEditarAbierto] = useState(false);
    const [modalTitulo, setModalTitulo] = useState("");
    const [apoyoSelected, setApoyoSelected] = useState(null);
    const [fuente, setFuente] = useState(1);
    const [apoyoDet, setApoyoDet] = useState([]);
    const [listTipo, setListTipo] = useState([]);
    const [actualizDet, setActualizDet] = useState(false);
    const [buscar, setBuscar] = useState('');
    const [municipioList, setMunicipioList] = useState([]);
    const [municipioSelected, setMunicipioSelected] = useState(-1);
    const [seccionList, setSeccionList] = useState([]);
    const [seccionSelected, setSeccionSelected] = useState(-1);
    const [campoFilt, setCampoFilt] = useState({auxMun: 0, auxSecc: 0})
    const {auxMun, auxSecc} = campoFilt

    useEffect(() => {
        let data = qs.stringify({
            usuario: UsuId, estado: 15, tipo: 1, idDispositivo: auxDispV,
        });

        let url = 'lugar-municipio-tipo'

        function respuesta(response) {
            if (response.length !== 0) {
                setMunicipioList(response)
            }
        };
        guardarGenerales({...generales, Titulo: 'Apoyos'})
        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }, []);

    const llamadaListSecc = (auxMuni) => {
        let data = qs.stringify({
            usuario: UsuId, municipio: auxMuni, idDispositivo: auxDispV,
        });
        let url = "lugar-seccion";

        function respuestaSecc(auxiliar) {
            if (auxiliar[0].Id != -1) {
                return setSeccionList(auxiliar)
            }
        }

        llamaApiCargaToken(data, url, respuestaSecc, ErrorGeneral, setEspera, history, source);
    }

    /*    const buscarRegistro = () => {
           setApoyoSelected(null)
           setPerfil([])
           setIdBotonEditar(-1)
           let data = qs.stringify({
               usuario: UsuId, cadena: buscar, idDispositivo: auxDispV,
           });

           let urlBuscar = 'apoyo-publico-perfil-search';
           if (fuente === 1) {
               urlBuscar = "apoyo-perfil-search"
           }
           if (fuente === 2) {
               urlBuscar = "apoyo-publico-perfil-search"
           }

           function respuesta(response) {
               if (response.length !== 0&& response[0].Id!==0) {
                   setPerfil(response)
               }
           };llamaApiCargaToken(data, urlBuscar, respuesta, ErrorGeneral, setCargandoPerfil, history, source);
       } */

    const buscarRegistro2 = () => {
        setApoyoSelected(null)
        setPerfil([])
        setIdBotonEditar(-1)
        let data = qs.stringify({
            usuario: UsuId, municipio: auxMun, seccion: auxSecc, cadena: buscar, idDispositivo: auxDispV,
        });

        let urlBuscar2 = 'apoyo-perfil-search-filtro';

        function respuesta(response) {
            if (response.length !== 0 && response[0].Id !== 0) {
                setPerfil(response)
                guardarDatos(response)
                numeroPag(response, tamMax, setTotalPaginas, setNumPagina, setPerfil)
            }
        };llamaApiCargaToken(data, urlBuscar2, respuesta, ErrorGeneral, setCargandoPerfil, history, source);
    }

    const recargar = () => {
        setFuente(fuente)
        setApoyoSelected(null)
        setMunicipioSelected(-1)
        setSeccionSelected(-1)
        setSeccionList([])
        setBuscar('')
        guardarDatos([])
        setCampoFilt({...campoFilt, auxMun: 0, auxSecc: 0})
        setTotalPaginas(0)
        return perfilTipo("apoyo-perfil-tipo");
    }

    const actualizar = () => {
        setActualizDet(!actualizDet)
        perfilTipo("apoyo-perfil-tipo")
    }

    const perfilTipo = (auxUrl) => {
        setApoyoSelected(null)
        setPerfil([])
        setBuscar('')
        setIdBotonEditar(-1)
        let data = qs.stringify({
            usuario: UsuId, idDispositivo: auxDispV,
        });
        let url = auxUrl;

        function respuesta(response) {
            if (response.length !== 0) {
                setPerfil(response)
            }
        };llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setCargandoPerfil, history, source);
    }

    function llenaListTipos() {
        let data = qs.stringify({
            usuario: UsuId, idDispositivo: auxDispV,
        });
        let url = "apoyo-tipo-list";

        function respuesta(response) {
            if (response) {
                let axnvo = []
                response.sort(function (a, b) {
                    return a.Id - b.Id;
                });
                response.forEach(element => {
                    if (element.Id !== 0) {
                        axnvo.push(element)
                    }
                });
                setListTipo(axnvo)
            }
        }

        llamaApiCargaToken(data, url, respuesta, ErrorGeneral, setEspera, history, source);
    }

    useEffect(() => {
        if (!authUser()) {
            localStorage.clear()
            history.push("/");
        } else {
            let auxiliar = true
            setIdBotonEditar(-1)
            setApoyoSelected(null)
            if (listTipo.length === 0) {
                llenaListTipos()
            }
            if (fuente === 1) {
                perfilTipo("apoyo-perfil-tipo");
                auxiliar = false
            }
            if (auxiliar && fuente === 2) {
                perfilTipo("apoyo-publico-perfil-tipo");
            }

        }
    }, [fuente]);

    function handleClickEditar(row) {
        if (row.Id !== idBotonEditar) {
            setIdBotonEditar(row.Id);
            setApoyoSelected(row);
        }
    }

    function abrirModalEdit(row) {
        setModalEditarAbierto(!modalEditarAbierto);
        if (apoyoSelected.Nombre) {
            setModalTitulo("Editar Perfil de " + apoyoSelected.Nombre);
        } else {
            setModalTitulo("Editar Perfil de " + apoyoSelected.Nom);
        }
    }

    const onChangeFuente = (e) => {
        if (e !== null && e !== undefined) {
            setFuente(e.target.value)
        } else {
            return
        }
    }

    const onChangeMunicipio = (e) => {
        if (e !== null && e !== undefined) {
            setMunicipioSelected(e.target.value)
            if (e.target.value === -1) {
                setCampoFilt({...campoFilt, auxMun: 0, auxSecc: 0})
                setSeccionSelected(-1)
                setSeccionList([])
            } else {
                setCampoFilt({...campoFilt, auxSecc: 0, auxMun: e.target.value})
                setSeccionSelected(null)
                return llamadaListSecc(e.target.value);
            }

        }
    }

    const onChangeSeccion = (e) => {
        if (e !== null && e !== undefined) {
            setCampoFilt({...campoFilt, auxMun: 0, auxSecc: e.target.value})
            setSeccionSelected(e.target.value)
        }
    }

    const celda = (alinear, estilo, contenido) => {
        return (<TableCell align={alinear} className={classes.tableCell} padding={'none'} style={estilo}>
            {contenido}
        </TableCell>)
    }

    const celdaEncabezado = (alinear, contenido) => {
        return (<TableCell align={alinear} className={classes.tableCell} padding={'none'}
                           style={{backgroundColor: process.env.REACT_APP_Fondo_Color, color: "white"}}>
            {contenido}
        </TableCell>)
    }

    const handleBuscar = (event) => {
        event.preventDefault()
        setBuscar(event.target.value)
    }

    const modalEditarApoyo = modalEditarAbierto ? (<EditarApoyo
        modalAbierto={modalEditarAbierto}
        setModalAbierto={setModalEditarAbierto}
        titulo={modalTitulo}
        UserId={UsuId}
        DeviceId={Dispvo}
        history={history}
        perfil={apoyoSelected}
        apoyo={apoyoDet}
        listTipo={listTipo}
        fuente={fuente}
        actualizar={actualizar}
    />) : null;

    const selectFuente = () => {
        return <FormControl size='small'
                            style={{minWidth: 8.5 + 'rem', marginBottom: .5 + 'rem', marginTop: .5 + 'rem'}}>
            <InputLabel size='small' variant="outlined" id="fuente-label-nvo">Apoyos por</InputLabel>
            <Select size='small' variant="outlined"
                    label="Apoyos por" id="fuente-select2" name="fuente"
                    value={fuente} onChange={onChangeFuente}
            >
                <MenuItem size='small' value={1} key={1}>
                    <em>Admin</em>
                </MenuItem>
                <MenuItem size='small' value={2} key={2}>
                    <em>Corchocolata</em>
                </MenuItem>
            </Select>
        </FormControl>
    }

    const sinContenido = () => {
        return (
            <TableRow key={'Sin Datos'}>
                <TableCell align="center">Sin</TableCell>
                <TableCell align="center">Registros</TableCell>
            </TableRow>
        )
    }

    const renglon = (row, index) => {
        return (
            <TableRow className={classes.tableRow} key={index}
                      classes={{hover: classes.hover, selected: classes.selected}}
                      onClick={() => handleClickEditar(row)} selected={row.Id === idBotonEditar}
            >
                <TableCell align="center" padding={"none"}>
                    {row.Id === idBotonEditar ?
                        <IconButton size="small" component={"span"}
                                    onClick={row.Id === idBotonEditar ? () => abrirModalEdit(row) : null}
                        >
                            <EditIcon size="small" style={{color: "white"}}/>
                        </IconButton>
                        : <div style={{width: 22 + 'px'}}/>}
                </TableCell>
                {celda("left", {fontSize: 12 + 'px',}, row.MunNom)}
                {celda("center", {
                    fontWeight: 700, width: 45 + 'px', paddingRight: 2 + 'px'
                }, row.SeccNom)}
                {celda("center", {fontSize: 13 + 'px',}, (row.Nombre ? row.Nombre : row.Nom))}
                {celda("center", {
                    width: 50 + 'px', fontSize: 12 + 'px', paddingLeft: 8 + 'px'
                }, row.Cel ? row.Cel : row.Tel)}
                {celda("center", {fontWeight: 700, width: 70 + 'px'}, row.ApoyoTotal ? row.ApoyoTotal : row.Total)}
            </TableRow>
        )
    }

    const Tabla = () => {
        return (
            <TableContainer component={Paper} className={classes.alturaTabla}>
                <Table size="small" stickyHeader aria-label="a dense table">
                    <TableHead>
                        <TableRow>
                            {celdaEncabezado("center", `${perfil.length !== 0 ? perfil.length : 0}`)}
                            {celdaEncabezado("center", "Municipio")}
                            {celdaEncabezado("center", "Seccion")}
                            {celdaEncabezado("center", "Nombre")}
                            {celdaEncabezado("center", "Teléfono")}
                            {celdaEncabezado("center", " Apoyos ")}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {perfil.length !== 0 ?
                            perfil.map((row, index) => (
                                renglon(row, index)))
                            : sinContenido()
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        )
    }


    function onChangePagina(event, newPage) {
        event.preventDefault();
        if (newPage === 0) {
            setPerfil(datos.slice(0, tamMax))
        } else {
            setPerfil(datos.slice(newPage * tamMax, newPage * tamMax + tamMax))
        }
        setNumPagina(newPage)
    }

    function generarFooter() {
        return (
            <TablePagination
                rowsPerPageOptions={false} colSpan={3}
                rowsPerPage={tamMax} count={datos.length}
                page={numPagina} lang='es'
                labelDisplayedRows={
                    ({from, to, count}) => {
                        return '' + from + '-' + to + ' de ' + count
                    }
                }
                onPageChange={onChangePagina}
                ActionsComponent={PaginacionTab}/>)
    }

    const paginacion = !espera && perfil.length !== 0 && totalPaginas > 1 ? generarFooter() : null;

    return (<>
        <Grid container direction="column">
            <Grid item>
                {/*{selectFuente()}*/}
                <FormControl size='small'
                             style={{width: '15rem', margin: '.5rem'}}>
                    <InputLabel size='small' variant="outlined" id="municipio">Municipio</InputLabel>
                    <Select size='small' variant="outlined"
                            label="Municipio" id="municipioS" name="municipio"
                            value={municipioSelected}
                            onChange={onChangeMunicipio}
                    >
                        <MenuItem value={-1} index={-1}>
                            <em>TODOS</em>
                        </MenuItem>
                        {municipioList.length !== 0 ?
                            municipioList.map((element, index) => (
                                <MenuItem key={index} value={element.Id}>
                                    {element.Nom}
                                </MenuItem>
                            ))
                            : <LinearProgress/>}
                    </Select>
                </FormControl>
                <FormControl size='small'
                             style={{minWidth: 8.5 + 'rem', marginBottom: .5 + 'rem', marginTop: .5 + 'rem'}}>
                    <InputLabel size='small' variant="outlined" id="seccion">Seccion</InputLabel>
                    <Select size='small' variant="outlined"
                            label="Seccion" id="seccionS" name="seccion"
                            value={seccionSelected} onChange={onChangeSeccion}
                    >
                        {seccionList.length !== 0 ? seccionList.map((element, index) => (
                                <MenuItem key={index} value={element.Id}>
                                    {element.Numero}
                                </MenuItem>
                            ))
                            :
                            <MenuItem value={-1} index={-1}>
                                <em>Elija un municipio</em>
                            </MenuItem>}
                    </Select>
                </FormControl>
                <FormControl size='small'
                             style={{minWidth: 8.5 + 'rem', margin: .5 + 'rem'}}>
                    <TextField id="outlined-basic" label="Buscar" variant="outlined" size={'small'} value={buscar}
                               onChange={handleBuscar}/>
                </FormControl>
                <FormControl size='small'>
                    <Tooltip title={'Buscar'}>
                        {buscar.length > 0 ?
                            <IconButton onClick={buscarRegistro2}>
                                <SearchIcon/>
                            </IconButton>
                            : <IconButton disabled={true}> <SearchIcon/></IconButton>
                        }
                    </Tooltip>
                </FormControl>
                <FormControl size='small'>
                    <Tooltip title={'Recargar'}>
                        <IconButton onClick={recargar}>
                            <ReplayIcon/>
                        </IconButton>
                    </Tooltip>
                </FormControl>
            </Grid>
            <Grid item>
                {cargandoPerfil === true ?
                    <Box width={'40rem'}> <LinearProgress/></Box>
                    : Tabla()
                }
                {paginacion}
            </Grid>
            {apoyoSelected !== null ?
                <ApoyosSelected apoyoSelected={apoyoSelected} fuente={fuente} apoyo={apoyoDet}
                                setApoyo={setApoyoDet} actualizar={actualizDet}
                /> : null}
        </Grid>
        {modalEditarApoyo /* */}
    </>);
};

export default Apoyo;
